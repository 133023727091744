import { _get, _post, _put } from "./API";
const baseURL = process.env.REACT_APP_DONATION_ENDPOINT;
const localURL = '/auth';
export const PublicAuth : string[] = [`${baseURL}${localURL}/signin`, `${baseURL}${localURL}/otp`, `${baseURL}${localURL}/otp/validate`, `${baseURL}/donors/signup`]

export const AuthAPI = {

    
    signUpOrganiser : async (data:any) => {
      return await _post(`${baseURL}/signup/organizer`,data);
    },
    signIn: async (data: any) => {
      return await _post(`${baseURL}${localURL}/signin`,data);
    },
    getOtp: async (data: any) => {
      return await _post(`${baseURL}${localURL}/otp`,data);
    },
    validateOtp:  async (data: any) => {
      return await _post(`${baseURL}${localURL}/otp/validate`,data);
    },
    refreshToken:  async (data: any) => {
      return await _post(`${baseURL}${localURL}/refresh`,data);
    },
}