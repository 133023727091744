import React, { Component, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import CardComponent from "../Card/CardComponent";
import './CarouselComponent.scss';
import { UserCardComponent } from "../UserCard/UserCardComponent";
import { ImageCard } from "../../../configs/Utils";
import HomeCard from "../HomeCard/HomeCard";

export const CarouselComponent = (props: { cards: any, cardFor?:'fundraiser' | 'ngo', showCount: number, vertical?: boolean, reverse?: boolean, entity?: 'contributors' | 'thumbnails' | 'homeCards', autoplay?: boolean, arrows?: boolean }) => {

    const slider = useRef<any>();

    const settings = {
        dots: (props.entity === 'contributors' || props.entity === 'homeCards') ? false : true,
        infinite: true,
        fade: (props.entity === 'thumbnails' || props.entity === 'homeCards') ? false : true,
        speed: props.entity === 'thumbnails' ? 2000 : props.entity === 'homeCards' ? 1000 : 1000,
        slidesToShow: props.showCount,
        slidesToScroll: 1,
        vertical: props.vertical,
        verticalSwiping: props.vertical,
        rtl: props.reverse,   
        autoplay: props.autoplay ? props.autoplay : true,
        autoplaySpeed: (props.entity === 'contributors' || props.entity === 'thumbnails') ? 1000 : props.entity === 'homeCards' ? 4000 : 5000,
        swipe: true,
        touchMove: true,
        arrows: props.arrows
    };


    return (
        <div className="sliderWrap">
            <Slider {...settings} ref={slider}>
                {
                    props.cards.map((card: any, idx: number) => (
                        <div key={idx}>
                            {props.entity === 'contributors' ? <UserCardComponent user={card} />
                                : props.entity === 'thumbnails' ? <ImageCard image={card} />
                                    : props.entity === 'homeCards' ? <HomeCard card={card} cardFor={props.cardFor} />
                                        : <CardComponent card={card} hideDetails={true} />
                            }
                        </div>
                    ))
                }
                {/* {   
                props.cards.map((card: any, idx: number) => (
                    <div key={idx} onClick={() => _onClick(idx)}>
                    {   props.entity === 'contributors' ? <UserCardComponent user={card}/>
                        : props.entity === 'thumbnails' ? <ImageCard image={card}/>
                        : props.entity === 'pujo' ? <PujoCard card={card}/>
                        : <CardComponent card={card} hideDetails={true}/>
                    }
                    </div>
                ))
            } */}
            </Slider>
        </div>
    );
}