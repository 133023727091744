import { useState, useEffect } from 'react';
import { FaHeart } from "react-icons/fa";
import { Card, CardContent, CardMedia } from '@mui/material';
import { ACTION_CONSTANTS, _getMyTotalDonations, canDonate, getImageUrl, isProgramUpcoming, isTokenValid } from '../../../configs/Utils';
import './CardComponent.scss';
import { useNavigate } from "react-router-dom";
import DonationDialog from '../../Dialogs/DonationDialog/DonationDialog';
import Moment from 'moment';
import { MessageService, userSubject } from '../../../services/helper.service';
import { FundInfo, Rupee } from '../Widgets';
import { MdLockClock } from 'react-icons/md';
import DonationsBreakupDialog from '../../Dialogs/DonationsBreakupDialog/DonationsBreakupDialog';




const CardComponent = (props: { card: any, showDonation?: boolean, hideDetails?: boolean | undefined, onClickHandle?: any | undefined }) => {
    const styles = JSON.parse(localStorage.getItem('styles') as string);
    const [opendialog, setDialogflag] = useState<boolean>(false);
    const [openDonationsdialog, setDonationsDialogflag] = useState<boolean>(false);
    const [program, setProgramDetail] = useState<any>(props.card);
    const [userType, setUserType] = useState<any>();
    const [dialogOptions, setDialogOptions] = useState<any>();
    const navigate = useNavigate()
    const openConfirmationDialog = (actionType: string, text: string) => {
        setDialogOptions({ type: actionType, header: text, buttonList: [ACTION_CONSTANTS.CLOSE], action: dialogAction })
        setDialogflag(true)
    }

    const _onClickOrganisation = (id: any) => {
        if (isTokenValid()) {
            navigate(`../organisers/${id}`);
        } else {
            MessageService.setStatus({ type: 'error', text: 'Please login to continue' });
        }
    }

    const _onClickProgram = (card_name: any) => {
        navigate(`../events/${card_name}`)
    }

    const _onDonateClick = () => {
        if (isTokenValid()) {
            let userid = localStorage.getItem(ACTION_CONSTANTS.USERID);
            userid ? setDialogflag(true) : openConfirmationDialog(ACTION_CONSTANTS.ERROR, 'Please login to donate!');
        } else {
            MessageService.setStatus({ type: 'error', text: 'Please login to continue' });
        }

    }
    const _toggleMyDonations = (action: 'open' | 'close', data?: any) => {
        action === 'open' ? setDonationsDialogflag(true) : setDonationsDialogflag(false);
        if (action === 'close') {
            setProgramDetail(data);
        }
    }

    const dialogAction = (action: any, updatedProgram: any) => {
        setDialogflag(false);
        if (updatedProgram) setProgramDetail(updatedProgram);
    }

    useEffect(() => {
        if (props && props.card)
            props.card.isUpcoming = isProgramUpcoming(props.card.startDate)
        const subscription = userSubject.subscribe((flag: boolean) => {
            setUserType(localStorage.getItem('userType'));
        })
        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [])

    return (
        <>
            {opendialog && <DonationDialog program={program} dialogAction={dialogAction} />}
            {openDonationsdialog && <DonationsBreakupDialog program={program} dialogAction={_toggleMyDonations} />}

            <Card className={'card relative ' + (program.closed && 'closedCard')} sx={{ display: 'flex', flexDirection: 'column' }} >
                <CardMedia component="div" className='cardMedia' image={program.bkImageCodes?.length > 0 ? getImageUrl(program.bkImageCodes[0]) : getImageUrl()} >

                    {((userType === ACTION_CONSTANTS.DONOR || userType === ACTION_CONSTANTS.USER) && props.showDonation) &&
                        <div className="donationText absolute" onClick={() => _toggleMyDonations('open')}>you donated <b><Rupee />{_getMyTotalDonations(program.costCodes)}</b></div>
                    }
                    <div className={'cardHeader ' + styles.cardHeader} onClick={() => _onClickProgram(program.code)}>
                        {program.name}
                        <div className="cardCategory">{program.campaignName}
                            { program.closed && <span className='closedTab'>Closed</span> }
                        </div>
                    </div>
                </CardMedia>
                <CardContent sx={{ flexGrow: 1 }}>
                    <div className="cardContent">
                        <FundInfo data={program} />
                        {program.description && <div className='cardDescription'>
                            <p className="text_ellipsis">{program.description}</p>
                        </div>}
                        <div className='dates'>
                            <b>{Moment(program.startDate).format('DD MMM YYYY')}</b> to <b>{Moment(program.endDate).format('DD MMM YYYY')}</b>
                        </div>
                    </div>
                    {
                        !props.hideDetails &&
                        <div className='cardfooter flex'>
                            {/* { (userType === ACTION_CONSTANTS.DONOR || userType === ACTION_CONSTANTS.USER) && program.albums && 
                                <div className={'owner col ' + styles.cardAlbumsInfo} onClick={() => _onClickAlbums()}><span>{program.albums.length} Events</span></div>
                            } */}
                            <div className={' owner col ' + styles.cardOwnerInfo} onClick={() => _onClickOrganisation(program.createdBy)}>by <span>{program.createdBy}</span></div>
                        </div>
                    }
                </CardContent>
                {
                    !props.hideDetails && <div className='cardFooter flex'>
                        {canDonate(program) &&
                            <div className={styles.tertiaryBtnStyle + ' footerBtn col flex donateBtn ' + (props.card.isUpcoming && 'disabledItem')} onClick={() => _onDonateClick()}>
                                {props.card.isUpcoming ? <MdLockClock className={styles.icon} /> : <FaHeart className={styles.icon} />}
                                {props.showDonation ? 'Donate again' : 'Donate Now'}
                            </div>
                        }
                    </div>
                }
            </Card>
        </>
    )
}


export default CardComponent;
