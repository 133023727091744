import { _get, _post, _put } from "./API";
const baseURL = process.env.REACT_APP_DONATION_ENDPOINT;

export const PaymentAPI = {
    donateToProject: async(id: any, data: any) => {
      return await _post(`${baseURL}/projects/${id}/donate`, data);
    },
    // initiatePayment:async(id: any, data: any) => {
    //     return await _post(`${baseURL}/projects/${id}/donate`, data);
    // }
}