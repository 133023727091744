import { _get, _post, _put } from "./API";
const baseURL = process.env.REACT_APP_DONATION_ENDPOINT;
const localURL = '/donors';
export const PublicDonor : string[] = []

export const DonorsAPI = {
    
    donorSignup: async (data: any) => {
      return await _post(`${baseURL}${localURL}/signup`,data);
    },
    getDonationsByDonor: async (id: any) => {
        return await _get(`${baseURL}${localURL}/donations?uid=${id}`);
    }
}