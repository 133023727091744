import React, { useEffect, useState } from 'react';
import './DonationsBreakupDialog.scss';
import Dialog from '@mui/material/Dialog';
import { ACTION_CONSTANTS, Loader, getPercent, isTokenValid } from '../../../configs/Utils'
import { MessageService } from '../../../services/helper.service';
import { FaHeart } from 'react-icons/fa';
import { DonorsAPI, ProjectAPI } from '../../../services';
import Moment from 'moment';
import { Rupee } from '../../components/Widgets';
import { PaymentAPI } from '../../../services/PaymentAPI';
import { Checkbox } from '@mui/material';
import { MdDownload, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
const app = localStorage.getItem('app');


const DonationsBreakupDialog = (props: { program: any, dialogAction: any }) => {
    const styles = JSON.parse(localStorage.getItem('styles') as string);
    const [open, setOpen] = React.useState(false);
    const [program, setProgram] = React.useState<any>(props.program);
    const [reasons, setdonationReasons] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [expandedIdx, setBreakupIdx] = useState<number | null>(0);


    let userid = localStorage.getItem(ACTION_CONSTANTS.USERID);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event?: any, reason?: any) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return false;
        }
        setOpen(false);
    }
    useEffect(() => {
        handleClickOpen()
    }, [])

    const onActionClick = (action: any) => {
        switch (action) {
            case 'close':
                props.dialogAction('close', program);
                break;
        }
    }

    const _expandBreakup = (idx: number) => {
        expandedIdx === idx ? setBreakupIdx(null) : setBreakupIdx(idx);
    }

    const _getMyDonationByCC = (donations: any) => {
        let amount = 0;
        if (userid) {
            donations.forEach((d: any) => {
                if (userid === d.donatedBy) amount += d.amount;
            });
            return amount;
        }
        else return amount;
    }

    const _downloadReceipt = (txn: any) => {
        MessageService.downloadReceipt(txn);
    }
    const _getDonationsByDonor = () => {
        setIsLoading(true)
        DonorsAPI.getDonationsByDonor(userid).then((res: any) => {
            let prog = res.filter((r: any) => r.code === program.code)[0];
            setIsLoading(false);
            setProgram(prog);
        })
    }
    const _verifyTxn = (txn: any) => {
        setIsLoading(true)
        ProjectAPI.verifyPaymentStatus(txn).then((res: any) => {
            setIsLoading(false);
            if (res) {
                _getDonationsByDonor();
            }
        })
    }
    const _getDonationsBreakup = () => {
        let ccArr = program.costCodes.map((cc: any) => cc.code);
        setIsLoading(true)
        ProjectAPI.getDonationsBreakup(userid, program.code, ccArr).then((res: any) => {
            setIsLoading(false);
            setdonationReasons(res);
        })
    }

    useEffect(() => {
        _getDonationsBreakup();
    }, [props.program])

    return (
        <>
            <Dialog PaperProps={{ className: 'donationDialogBox' }}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                disableEscapeKeyDown={true}
            >
                {isLoading && <Loader />}
                <div className='dialogHeader flex flex-wrap justify-content-between'>
                    {program && <>
                        <div className='flex programHeader'>
                            <span className='flex donateToContainer'>Donations Breakup for &nbsp;&nbsp;<b className={'subheader flex ' + (app === ACTION_CONSTANTS.EDAAN ? 'edaanHeader' : 'epujoHeader')}>{program.name}</b></span>
                            <span className='flex campaignname'>{program.campaignName}</span>
                        </div>
                        <div className='flex'>
                            <div className='costHeadTarget flex'>
                                <span className='target'>Total Fund Raised</span>
                                <b className='fundRaised'><Rupee />{program.totalFundRaised}</b>
                            </div>
                            <div className='costHeadTarget flex'>
                                <span className='target'>Fundraiser Target</span>
                                <b className='projectBudget'><Rupee />{program.projectBudget}</b>
                            </div>
                        </div>
                    </>}
                </div>
                <div className='dialogBody'>
                    <div className='costCodesWrap'>
                        {
                            program && program.costCodes && program.costCodes.map((cc: any, idx: number) => (
                                <>
                                <div key={cc.code} className='costCodeRow flex flex-wrap'>
                                    <div className='headname flex'  onClick={() => _expandBreakup(idx)}>
                                        <div>{cc.name}
                                            { expandedIdx === idx ? <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/> }
                                        </div>
                                    </div>
                                    <div className='flex costAmounts justify-content-right'>
                                        <div className='flex webViewTargets'>
                                            <div className='costHeadTarget flex'>
                                                <span className='target'>You Donated</span>
                                                <b className={'fundRaised ' + (!userid && 'disabledItem')}><Rupee />{_getMyDonationByCC(cc.donations)}</b>
                                            </div>
                                            {/* <MdDownload onClick={() => _downloadReceipt()}/> */}
                                        </div>
                                        <div className='flex mobileViewTargets'>
                                            <b className={'fundRaised ' + (!userid && 'disabledItem')}><Rupee />{_getMyDonationByCC(cc.donations)}</b>
                                        </div>
                                    </div>
                                </div>
                                {
                                    expandedIdx === idx && <div className='donationsTxnWrap'>
                                        {
                                            cc.donations.map((d: any) => (
                                                <>{ d.donatedBy === userid && 
                                                    <div className={'txnTab inline ' + ((!d.result || d.result === ACTION_CONSTANTS.PAYMENT_FAILED) ? 'failedTxn' : '')}>
                                                    <Rupee/> <b>{d.amount}</b> &nbsp; 
                                                    { d.result == ACTION_CONSTANTS.PAYMENT_PENDING && <div className='downloadBtn verifyBtn' onClick={() => _verifyTxn(d.id)}><span className='receiptspan'>Verify</span></div> }
                                                    { d.result == ACTION_CONSTANTS.PAYMENT_SUCCESS && <div className='downloadBtn' onClick={() => _downloadReceipt(d.id)}><MdDownload className='downloadReceipt'/><span className='receiptspan'>Receipt</span></div>}
                                                </div> }</>
                                            ))
                                        }
                                    </div>
                                }
                                { reasons?.length > 0 && expandedIdx === idx && reasons[idx] && <div className='donationReasonWrap'>
                                    {reasons[idx].map((r: any) => (
                                        <div className='reasonRow'>
                                            <div className='reasonCol largeCol inline'>{r.reason}</div>
                                            <div className='reasonCol smallCol inline'><Rupee />{r.usedAmount}</div>
                                            <div className='reasonCol smallCol inline'>{Moment(r.expenditureDt).format('DD MMM YYYY')}</div>
                                        </div>
                                    ))}
                                </div> }
                                </>
                            ))
                        }
                    </div>
                </div>
                <div className='dialogFooter flex'>
                    <div className={styles.tertiaryBtnStyle + ' cancelBtn'} onClick={() => onActionClick('close')}>Cancel</div>
                </div>
            </Dialog>

        </>
    )
}
export default DonationsBreakupDialog;