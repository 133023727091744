import React from "react";
import './OrganiserCard.scss'
import { Card, CardContent, CardMedia } from '@mui/material';
import { ACTION_CONSTANTS, getImageUrl } from '../../../configs/Utils';
import { useNavigate } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import { MdCampaign, MdLocationOn } from "react-icons/md";


const OrganiserCard = (props: { card: any, hideDetails?: boolean | undefined, onClickHandle?: any | undefined }) => {
    const styles = JSON.parse(localStorage.getItem('styles') as string);
    const navigate = useNavigate()

    return (
        <Card className='card' sx={{ display: 'flex', flexDirection: 'column' }} onClick={() => props.onClickHandle({action: ACTION_CONSTANTS.ORGANISER_CLICK, data: props.card.id})}>
            <CardMedia component="div" sx={{ pt: '56.25%', position: 'relative' }} image={props.card.bk_image ? getImageUrl({code: props.card.bk_image}) : getImageUrl()} >
                <div className="cardHeader">
                    {props.card.legal_name}
                </div>
                { props.card?.logo && <img src={getImageUrl({code: props.card?.logo}, 'profile')} className='logo absolute'/> }
            </CardMedia>
            { props.card.about && 
                <CardContent sx={{ flexGrow: 1 }}>
                    <div className="cardContent relative">
                        <div className={"organiserDesc " + styles.fontColorDark}>
                            <p className="text_ellipsis">{props.card.about}</p>
                        </div>
                    </div>
                </CardContent>
            }
            <CardMedia >
                <div className="cardFooter flex">
                    <div className="footerInfo supporter flex"><MdLocationOn className="icon"/>&nbsp;<div className="address">{props.card.address.line}, {props.card.address.city}</div></div>
                    {/* <div className="footerInfo supporter flex"><MdCampaign className="icon"/>&nbsp;<b className="amount">2</b></div> */}
                </div>
            </CardMedia>
        </Card>
    )
}
export default OrganiserCard;