import './UserCardComponent.scss';

export const UserCardComponent = (props: {user:any}) => {
    return(
        <div className='contributor flex'>
            <div className='flex profileIcon'>
                <img src={process.env.PUBLIC_URL + "/images/profile.png"} alt='donor_image'/>
            </div>
            <div className='flex donorId'>
                <b className='flex'>{props.user.name}</b>
                <b className='amount'>{'\u20B9'}&nbsp;{props.user.amount}</b>
            </div>
        </div>
    );
}