import Grid from '@mui/material/Grid';
import CardComponent from '../Shared/components/Card/CardComponent';
import { CardMedia, Tooltip } from '@mui/material';
import { FundraisersByCategories, TopDonors, TopFundraisers, UrgentRequirement } from '../Shared/components/Widgets';
import React from 'react';
import OrganiserCard from '../Shared/components/OrganiserCard/OrganiserCard';
// import PujoCard from '../Shared/components/HomeCard/HomeCard';
import { CarouselComponent } from '../Shared/components/Carousel/CarouselComponent';
import { FcAnswers } from "react-icons/fc";
import { DmsAPI } from '../services';
import jwt from 'jwt-decode'
import {FacebookIcon, FacebookShareButton} from 'react-share'

const loaderWrap = {
    background: '#fffbfb9e',
    width: '100%',
    height: '100%',
    zIndex: '100'
};
const loader = {
    // margin: '400px auto'
};
const marginRight = {
    marginRight: '7px'
};

const loadingMsg = {
    margin: '0px auto',
    color: '#263442',
    fontSize: '18px',
    letterSpacing: '2px'
}
export const getPrivateDoclist = () =>{
    const platform = JSON.parse(localStorage.getItem(ACTION_CONSTANTS.PLATFORM) || '{}');
    const docCategories: any[] = platform? platform.docCategories : [];
    let privateDocs = docCategories.map(function(item:any) {
        if(!item.public)
        return item['code'];
        return 
      });
      return privateDocs
}
export const assignPublicPropertyToDocs = (docList:any) =>{
    let privateDocs = getPrivateDoclist()
    docList.forEach((doc:any) => {
        if(doc.categoryCode && privateDocs.includes(doc.categoryCode)) doc.public = false
        else doc.public = true
    });
    return docList
}
export const ACCEPTED_DOC_TYPES=['application/pdf'];
export const ACCEPTED_IMAGE_TYPES=['image/png','image/jpeg']
export const _logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem(ACTION_CONSTANTS.USER);
    localStorage.removeItem(ACTION_CONSTANTS.USERID);
    localStorage.setItem('userType', 'user');
    localStorage.removeItem('refreshtoken');
    localStorage.removeItem(ACTION_CONSTANTS.ADMIN_UDIN);
    window.location.href= process.env.REACT_APP_BASEHREF as string;
  }
export const ACTION_CONSTANTS: any = {
    'PLATFORM': 'platform',
    'EPUJO': 'EPujo',
    'EDAAN': 'EDaan',
    'ORGANISER_CLICK': 'organiserClick',
    'ORGANISER': 'ngo',
    'FUNDRAISER': 'fundraiser',
    'COMMITTEE': 'committee',
    'PAYMENTMERCHANT': 'paymentmerchant',
    'PUJO': 'pujo',
    'USER': 'user',
    'DONOR': 'donor',
    'ADMIN': 'admin',
    'CONFIRM': 'confirm',
    'CLOSE': 'close',
    'WARNING': 'warning',
    'ERROR': 'error',
    'SUCCESS': 'success',
    'APPROVE': 'approve',
    'DECLINE': 'decline',
    'VIEW': 'view',
    'REVIEW': 'review',
    'USERID': 'userid',
    'SUSPEND': 'suspend',
    'REVOKE': 'revoke',
    'ONGOING': 'ongoing',
    'UPCOMING': 'upcoming',
    'PAST': 'past',
    'USERTYPE': null,
    'RECEIPTS':'receipts',
    'ADMIN_UDIN':'admin_udin',
    'PAYMENT_SUCCESS': 'SUCCESS',
    'PAYMENT_PENDING': 'PENDING',
    'PAYMENT_FAILED': 'FAILED',
    'DOWNLOAD':'download'
}
export let USERTYPE = ACTION_CONSTANTS.USER
export const menus: any = {
    'EDaan': {
        admin_menuItems: [{ name: 'Dashboard', id: 'dashboard' }, { name: 'Receipts', id: 'receipts' }],
        payment_partner_menuItems: [{ name: 'Dashboard', id: 'onboardings' }],
        org_menuItems: [{ name: 'Fundraisers', id: 'events' }, { name: 'Profile', id: 'profile' }, { name: 'Utilizations', id: 'expenditures' }],
        user_menuItems: [{ name: 'Fundraisers', id: 'events' }, { name: 'NGO', id: 'organisers' }, { name: 'Profile', id: 'profile' }],
        guest_menuItems: [{ name: 'Fundraisers', id: 'events' }, { name: 'NGO', id: 'organisers' }],
        org_menuItems_without_approval: [{ name: 'Edit Organiser', id: 'events',disableclick:true }]

    },
    'EPujo': {
        admin_menuItems: [{ name: 'Dashboard', id: 'dashboard' }],
        org_menuItems: [{ name: 'Pujo', id: 'events' }, { name: 'Profile', id: 'profile' }],
        user_menuItems: [{ name: 'Pujo', id: 'events' }, { name: 'Committees', id: 'organisers' }, { name: 'Profile', id: 'profile' }],
        guest_menuItems: [{ name: 'Pujo', id: 'events' }, { name: 'Committees', id: 'organisers' }],
        org_menuItems_without_approval: [{ name: 'Edit Committee', id: 'events',disableclick:true }]
    }
}

export const pujos = [
    { id: 'p1', name: 'Bagbajar Sarbojonin', organiserName: 'Baghbajar Sarbojonin', location: 'Bagbazar, North Kolkata', img: ['pujo1.jpg'], target: 400000, fund: 256800, supporters: 145 },
    { id: 'p2', name: 'Santosh Mitra Square', organiserName: 'Santosh Mitra Square', location: 'Bowbazar', img: ['pujo2.jpg'], target: 400000, fund: 256800, supporters: 145 },
    { id: 'p3', name: 'College Square', organiserName: 'College Square', location: '53 College Street', img: ['pujo3.jpg'], target: 400000, fund: 256800, supporters: 145 },
    { id: 'p4', name: 'Badamtal Asar Sangha', organiserName: 'Badamtal Asar Sangha', location: 'Kalighat', img: ['pujo4.jpg'], target: 400000, fund: 256800, supporters: 145 },
    { id: 'p5', name: 'Jodhpur Park', organiserName: 'Jodhpur Park', location: 'Dhakuria', target: 400000, img: ['pujo5.jpg'], fund: 256800, supporters: 145 },
    { id: 'p6', name: 'Kumartuli', organiserName: 'Kumartuli Park', location: 'Sovabazar', target: 400000, img: ['pujo6.jpg'], fund: 256800, supporters: 145 }
]
export const pujoEvents = {
    id: 'p1', theme: 'Some random theme description', description: 'The history of BAGHBAZAR SARBOJONIN DURGOTSAB AND EXHIBITION is an inseparable part of the history and progress of Bengali culture for near about a century it has seen all the transitions and critical periods of history during this long enriched span of time.', events: [
        { id: 'e1', name: 'Maha Shosti', images: ['pujo6.jpg', 'pujo2.jpg', 'pujo1.jpg', 'pujo4.jpg', 'pujo5.jpg'], date: '19 Oct, 2023' },
        { id: 'e2', name: 'Maha Saptami', images: ['pujo1.jpg', 'pujo4.jpg', 'pujo3.jpg', 'pujo2.jpg', 'pujo5.jpg'], date: '20 Oct, 2023' },
        { id: 'e3', name: 'Maha Ashtami', images: ['pujo10.jpg', 'pujo8.jpg', 'pujo13.jpg', 'pujo4.jpg', 'pujo5.jpg'], date: '21 Oct, 2023' },
        { id: 'e4', name: 'Maha Nabami', images: ['pujo12.jpg', 'pujo7.jpg', 'pujo8.jpg', 'pujo9.jpg', 'pujo11.jpg'], date: '22 Oct, 2023' },
        { id: 'e5', name: 'Maha Dashami', images: ['pujo6.jpg', 'pujo2.jpg', 'pujo7.jpg', 'pujo8.jpg', 'pujo10.jpg'], date: '23 Oct, 2023' }
    ]
}
export const EdaanWidgets = [
    { id: 'all_categories', name: 'All Categories' },
    { id: 'urgent', name: 'Urgent Requirement / Soon To End' },
    { id: 'top', name: 'Top Fundraisers' },
    { id: 'grid_layout_all', name: 'All Fundraisers' }
]
export const EpujoWidgets = [
    { id: 'all_categories', name: 'All Categories' },
    { id: 'albums_ongoing', name: 'Ongoing Events' },
    { id: 'albums_upcoming', name: 'Upcoming Events' },
    { id: 'top', name: 'Top Pujo' },
    { id: 'grid_layout_all', name: 'Pujo Parikrama' }
]
export const sortArrayByDate = (arr: any, property: string) => {
    let sortedAsc = []
    if (arr) {
        sortedAsc = arr.sort(
            (date1: any, date2: any) => Number(new Date(date1[property])) - Number(new Date(date2[property])),
        );
    }
    return sortedAsc
}
export const EdaanProfileLayouts = [
    {
        id: 'layout_1',
        grid: [
            {
                row: '1', columns: [
                    { column: '1', width: '1/4', widgetIds: ['all_categories'] },
                    { column: '2', width: '1/2', widgetIds: ['urgent'] },
                    { column: '3', width: '1/4', widgetIds: ['about_us'] }
                ]
            },
            {
                row: '2', columns: [
                    { column: '1', width: '1', widgetIds: ['grid_layout_all'] },
                ]
            }
        ]
    },
    {
        id: 'layout_2',
        grid: [
            {
                row: '1', columns: [
                    { column: '1', width: '1/3', widgetIds: ['all_categories'] },
                    { column: '2', width: '1/3', widgetIds: ['urgent'] },
                    { column: '3', width: '1/3', widgetIds: ['top'] }
                ]
            }
        ]
    },
    {
        id: 'layout_3',
        grid: [
            {
                row: '1', columns: [
                    { column: '1', width: '1', widgetIds: ['grid_layout_all'] },
                ]
            },
            {
                row: '2', columns: [
                    { column: '1', width: '1/4', widgetIds: ['all_categories'] },
                    { column: '2', width: '1/2', widgetIds: ['urgent'] },
                    { column: '3', width: '1/4', widgetIds: ['top'] }
                ]
            }
        ]
    },
    {
        id: 'layout_4',
        grid: [
            {
                row: '1', columns: [
                    { column: '1', width: '1/4', widgetIds: ['all_categories'] },
                    { column: '2', width: '1/2', widgetIds: ['urgent'] },
                    { column: '3', width: '1/4', widgetIds: ['top'] }
                ]
            },
            {
                row: '2', columns: [
                    { column: '1', width: '1', widgetIds: ['grid_layout_all'] },
                ]
            }
        ]
    }
]
export const EpujoProfileLayouts = [
    {
        id: 'layout_1',
        grid: [
            {
                row: '1', columns: [
                    { column: '1', width: '2/3', widgetIds: ['albums'] },
                    { column: '2', width: '1/3', widgetIds: ['about_us'] }
                ]
            },
            {
                row: '2', columns: [
                    { column: '1', width: '1', widgetIds: ['grid_layout_all'] },
                ]
            }
        ]
    }
]
export const setClosedProjects = (projs: any) => {
    projs.forEach((proj: any) => {
        if (!proj.closed) {
            proj.closed = isProgramClosed(proj.endDate);
            if (proj.closed) proj.closedOn = proj.endDate;
        }
    });
    return projs;
}
export const isProgramUpcoming = (startDate: string) => {
    let creationDate = new Date(startDate);
    creationDate.setHours(0, 0, 0, 0);
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    return creationDate > today ? true : false
}
export const isProgramClosed = (endDate: string) => {
    let _endDate = new Date(endDate);
    _endDate.setHours(0, 0, 0, 0);
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    return _endDate < today ? true : false
}
export const isTokenValid = () => {
    let token = localStorage.getItem('token')
    let decodedData: any = token ? jwt(token) : '';
    let expirationDate = decodedData.exp;
    var current_time = Date.now() / 1000;
    if (expirationDate < current_time) {
        // localStorage.removeItem("token");
        token = null
    }
    return token ? true : false
}
export const _getDecimalPlaceValue = (val: any, dec?: number) => {
    dec = dec ? dec : 1000;
    return Math.round(val * dec) / dec;
}
export const getPercent = (val: number, total: number) => {
    return _getDecimalPlaceValue((val / total) * 100, 100);
}
export const getDateFromISO = (isoStr: string, opt: string) => {
    const dateTime = new Date(isoStr);
    switch (opt) {
        case 'date':
            const year = dateTime.getFullYear();
            const month = dateTime.getMonth() + 1;
            const day = dateTime.getDate();
            return `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
        case 'time':
            const hours = dateTime.getHours();
            const minutes = dateTime.getMinutes();
            const seconds = dateTime.getSeconds();
            return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }
}
export const getCurrentFiscalYear = () => {
    let today = new Date();
    let curMonth = today.getMonth();
    if (curMonth > 3) { //
        let nextYr1 = (today.getFullYear() + 1).toString();
        return today.getFullYear().toString() + "-" + nextYr1.charAt(2) + nextYr1.charAt(3);
    } else {
        let nextYr2 = today.getFullYear().toString();
        return (today.getFullYear() - 1).toString() + "-" + nextYr2.charAt(2) + nextYr2.charAt(3);
    }
}
export const _getMyTotalDonations = (heads: any) => {
    let amount = 0;
    let userid = localStorage.getItem(ACTION_CONSTANTS.USERID);
    heads.forEach((cc: any) => {
        cc.donations.forEach((d: any) => {
            if (userid === d.donatedBy) amount += d.amount;
        })
    });
    return amount;
}



export const extractFundRatioByCampaigns = (data: any) => {
    let campaigns: any = JSON.parse(localStorage.getItem(ACTION_CONSTANTS.PLATFORM) as string).campaigns;
    let res: any = [];
    if (campaigns) {
        campaigns.forEach((camp: any) => {
            let obj = { name: camp.name }
            let cmps = data.filter((d: any) => d.campaignName === camp.name);
            let returnObj = _getDonationByCampaign(cmps);
            if (returnObj.budget) {
                obj = { ...obj, ...returnObj }
                res.push(obj);
            }
        });
    }
    return res;
}
export const _formatDate = (todate: any) => {
    return `${todate.getFullYear()}-${("0" + (todate.getMonth() + 1)).slice(-2)}-${("0" + (todate.getDate())).slice(-2)}`
}
const _getDonationByCampaign = (cmps: any) => {
    let amt = 0;
    let budget = 0;
    cmps.forEach((c: any) => {
        c.donations.forEach((d: any) => {
            amt += d.amountCollected;
            budget += d.budget;
        })
    });
    return { donation: amt, budget: budget, value: amt ? (amt / budget) * 100 : 0 };
}


export const extractCostHeadsBreakupByCampaign = (data: any, campName: string) => {
    let campaigns: any = JSON.parse(localStorage.getItem(ACTION_CONSTANTS.PLATFORM) as string).campaigns;
    let res: any = [];
    let campaign;
    if (campaigns) {
        campaign = campaigns.filter((c: any) => c.name === campName)[0];
    }
    let activeCampaigns = data.filter((d: any) => d.campaignName === campName);
    campaign.costHeads.forEach((cc: any) => {
        let amt = 0, budget = 0, projects: any = [];
        activeCampaigns.forEach((c: any) => {
            if (!projects.includes(c.projectName)) projects.push(c.projectName);
            let d = c.donations.filter((d: any) => d.code === cc.code)[0];
            amt += d ? d.amountCollected : 0;
            budget += d ? d.budget : 0;
        })
        let obj = { ...cc, amt: amt, budget: budget, projects: projects };
        res.push(obj);
    });
    return res;
}


const _extractUniqueFiles = (files: any, newFiles: any) => {
    let res: any[] = [];
    newFiles.forEach((nf: any) => {
        if (nf.categoryCode) {
            let f_arr = files.filter((f: any) => f.categoryCode != nf.categoryCode);
            res = [...f_arr, ...newFiles];
        } else {
            res = [...files, ...newFiles];
        }
    });
    return res;
}
export const _mapDmsCodes = (dmsObjects: any, docCat?: any) => {
    const platform = JSON.parse(localStorage.getItem(ACTION_CONSTANTS.PLATFORM) || '{}');
    const docCategories: any[] = platform?.docCategories?.filter((a: any) => { return a.organizer }) || [];
    let objectList: { name: any, code: any, categoryCode: any, public: boolean }[] = []
    dmsObjects.forEach((dms: any) => {
        let categoryInfo = dms.documentCategory && docCategories ? docCategories.filter((a: any) => a.code == dms.documentCategory)[0] : null
        let obj: any
        obj = {
            name: dms.documentName,
            code: dms.documentId,

        }
        if (categoryInfo) {
            obj.categoryCode = categoryInfo.code
            obj.categoryInfo = categoryInfo.public
        }
        objectList.push(obj)
    });
    return objectList
}
// export const _mapDmsCodesToForm = (formbody: any, files: any, dmsObjects: any, type: string) => {
//     let arr: any = [];
//     dmsObjects.forEach((dms: any) => {
//       let file = files.filter((f: any) => f.file.name === dms.documentName)[0];
//       if (dms.documentCategory) {
//         file = files.filter((f: any) => (f.file.name === dms.documentName && f.type.code === dms.documentCategory))[0];
//         arr.push({ name: (file.name && file.name != '') ? file.name : dms.documentName, code: dms.documentId, categoryCode: file.type.code, public: file.type.public });
//       }
//       else arr.push({ name: (file.name && file.name != '') ? file.name : dms.documentName, code: dms.documentId, public: true });
//     });
//     let fb = { ...formbody };
//     if (type === 'kyc') fb.documents = fb.documents ? _extractUniqueFiles(fb.documents, arr) : arr;
//     if (type === 'images') fb.imageCodes = fb.imageCodes ? _extractUniqueFiles(fb.imageCodes, arr) : arr;
//     if (type === 'background') fb.bkImageCodes = arr;
//     // if (type === 'files') fb.documentCodes = fb.documentCodes ? [...fb.documentCodes, ...arr] : arr;
//     if (type === 'files') fb.documentCodes = fb.documentCodes ? _extractUniqueFiles(fb.documentCodes, arr) : arr;
//     return fb;
//     // setFormInstance(fb);
// }




export const downloadFile = (fileUrl: string) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.click()
}
export const extractDocuments = (codes: any) => {
    if (codes) {
        let arr: any = [...codes];
        arr.forEach((codeObj: any) => {
            codeObj.url = getImageUrl(codeObj);
        });
        return arr;
    }
    else return [];
}
export const _getUserNameByCode = (arr: any, code: string) => {
    return arr.filter((a: any) => a.code === code)[0].legal_name;
}

export const Loader = () => {
    return (
        <div style={loaderWrap} className='maxHeight absolute d-flex justify-content-center align-items-center'>
            <div className="text-center">
                <img src={process.env.PUBLIC_URL + '/icons/spinner.gif'} style={loader} alt='Loading...' />
            </div>
            {/* <div style={loader}>Loading...</div> */}
        </div>
    )
}
export const CustomBlankMsg = (props: { msg: any }) => {
    return (
        <div className="blankPage">
            <div className='blankPageContent'>
                <div className='custommsg'>{props.msg}</div>
            </div>
            {/* <img src="/assets/icons/home-new-user-illustration.png" style={blankPageImage} alt="logo" /> */}
        </div>
    );
}
export const BlankPageMsg = (props: any) => {
    return (
        <div className="blankPage">
            <div className='blankPageContent'>
                <FcAnswers className='icon' />
                <div className='msg'>No Content Yet!</div>
            </div>
            {/* <img src="/assets/icons/home-new-user-illustration.png" style={blankPageImage} alt="logo" /> */}
        </div>
    );
}
export const LoadingPage = (props: any) => {
    return (
        <div className="blankPage">
            <div style={loadingMsg}>Fetching Details
                &nbsp;&nbsp;<img src={process.env.PUBLIC_URL + "/icons/loading.gif"} alt="logo" />
            </div>
        </div>
    );
}
export const CardsLayout = (props: { entity: 'fundraiser' | 'ngo', showDonation?: boolean, cards: any, count?: number, hideDetails?: boolean, onClickHandle?: any }) => {
    return (
        <Grid container spacing={4}>
            {props.cards.map((card: any) => (
                <Grid item key={card.code} xs={12} sm={12} md={6} lg={props.count && props.count === 4 ? 3 : props.count === 2 ? 6 : 4}>
                    {props.entity === ACTION_CONSTANTS.FUNDRAISER && <CardComponent card={card} showDonation={props.showDonation} hideDetails={props.hideDetails} onClickHandle={props.onClickHandle} />}
                    {props.entity === ACTION_CONSTANTS.ORGANISER && <OrganiserCard card={card} hideDetails={props.hideDetails} onClickHandle={props.onClickHandle} />}
                    {/* { props.entity === ACTION_CONSTANTS.COMMITTEE && <PujoCard card={card} hideDetails={props.hideDetails} onClickHandle={props.onClickHandle} /> } */}
                </Grid>
            ))}
        </Grid>
    )
}

export const ImageCard = (props: { image: any }) => {
    return (
        <CardMedia component="div" sx={{ pt: '56.25%', position: 'relative' }} image={props.image} />
    );
}

export const getImagesList = (images?: any) => {
    let resImages: any = [];
    if (images) {
        images.forEach((image: any) => resImages.push(getImageUrl(image)));
        return resImages;
    } else {
        let im = getDefaultImage();
        resImages.push(im);
        return resImages;
    }
}
export const getImageUrl = (image?: any, imageFor?: 'background' | 'profile' | 'home_background') => {
    if (image && image.code) {
        let url = DmsAPI.getDocumentUrl(image.code);
        return url;
    } else {
        return getDefaultImage(imageFor);
    }
}
export const getDefaultImage = (imageFor?: 'background' | 'profile' | 'home_background') => {
    let app = localStorage.getItem('app');
    let img;
    switch (imageFor) {
        case 'background':
            img = app === ACTION_CONSTANTS.EDAAN ? `${process.env.PUBLIC_URL}/images/ngobackground.jpg` : `${process.env.PUBLIC_URL}/images/pujo/pujobackground.jpg`;
            break;
        case 'profile':
            img = `${process.env.PUBLIC_URL}/images/profile.png`;
            break;
        case 'home_background':
            img = `${process.env.PUBLIC_URL}/images/donations/defaultngo.jpg`;
            break;
    }
    if (!img) img = app === ACTION_CONSTANTS.EDAAN ? `${process.env.PUBLIC_URL}/images/donations/default.jpg` : `${process.env.PUBLIC_URL}/images/pujo/pujo9.jpg`;
    return img;
}

export const canDonate = (program: any) => {
    let userType = localStorage.getItem('userType');
    if ((userType === ACTION_CONSTANTS.DONOR || userType === ACTION_CONSTANTS.USER) && !program.closed) return true;
    return false;
}

export const SocialMediaIntregration = (props: { program: any, pageURL: string }) => {
    // dynamic url     
    let pgUrl = props.pageURL.toString().replace('/ngo/',"/user/");
    const fullURL = `${process.env.REACT_APP_BASE_URL}${pgUrl}`;
    
    
    return (
        <div style={{ marginLeft: 10, paddingTop: 5, }}>
            <Tooltip title='Share with Facebook'>
                <FacebookShareButton
                    hashtag={`#${props.program.name}`}
                    url={fullURL}
                    quote={props.program.name}
                >
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
            </Tooltip>
        </div>
    )
}
