import React, { useEffect, useState } from 'react';
import './DonationDialog.scss';
import Dialog from '@mui/material/Dialog';
import { ACTION_CONSTANTS, Loader, getPercent, isTokenValid } from '../../../configs/Utils'
import { MessageService } from '../../../services/helper.service';
import { FaHeart } from 'react-icons/fa';
import { DonorsAPI, ProjectAPI } from '../../../services';
import { Rupee } from '../../components/Widgets';
import { PaymentAPI } from '../../../services/PaymentAPI';
import { Checkbox } from '@mui/material';
const app = localStorage.getItem('app');


const DonationDialog = (props: { program: any, dialogAction: any }) => {
    const styles = JSON.parse(localStorage.getItem('styles') as string);
    const [open, setOpen] = React.useState(false);
    const [program, setProgram] = React.useState<any>(props.program);
    const [opendialog, setDialogflag] = useState<boolean>(false);
    const [dialogOptions, setDialogOptions] = useState<any>();
    const [paymentUrl, setpaymentUrl] = useState<any>();
    const [chekedCosthead, setchekedCosthead] = useState<any>();
    const [donations, setdonations] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);


    let userid = localStorage.getItem(ACTION_CONSTANTS.USERID);

    // let donations: any = {};
    const openConfirmationDialog = (actionType: string, text: string) => {
        setDialogOptions({ type: actionType, showInput: true, header: text, buttonList: [ACTION_CONSTANTS.CLOSE], action: dialogAction })
        setDialogflag(true)
    }
    const dialogAction = (action: any) => {
        setDialogflag(false)
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event?: any, reason?: any) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return false;
        }
        setOpen(false);
    }
    useEffect(() => {
        handleClickOpen()
    }, [])

    const _getProjectDetails = (code: string) => {
        ProjectAPI.getProjectDetailsById(code).then((res: any) => {
            setIsLoading(true)
            if(res)
            props.dialogAction('close', res);
            setIsLoading(false)
        })
    }

    const _onDonate = () => {
        let donation = {}
        let checkedCC = program.costCodes.filter((a: any) => a.checked)
        if (checkedCC) {
            donation = {
                "costCode": checkedCC[0].code,
                "amount": donations[checkedCC[0].code]
            }
        }
        let req = { pCode: program.code, donation: donation };
        setIsLoading(true)
        PaymentAPI.donateToProject(program.code, req).then((res: any) => {
            if (res) {
                localStorage.setItem('PostPaymentURL', window.location.href)
                let url = `${process.env.REACT_APP_PAYMENT_GATEWAY_URL}${res.payload}&output=embed`;
                window.location.replace(url);
            }
            setIsLoading(false)
        })
    }
    const _onAmountEnter = (e: any, code: any) => {
        donations[code] = e.target.value;
        setdonations(donations)
    }
    const onActionClick = (action: any) => {
        switch (action) {
            case 'close':
                props.dialogAction('close');
                break;
            case 'donate':
                if (userid && eligibleToDonate()) {
                    _onDonate();
                } else {
                    alert('Enter proper donations')
                    // openConfirmationDialog(ACTION_CONSTANTS.ERROR, 'Please login to donate!')
                }
                break;
        }
    }
    const eligibleToDonate = () => {
        let checkedCC = program.costCodes.filter((a: any) => a.checked)[0];
        return checkedCC && donations && donations[checkedCC.code] && donations[checkedCC.code] != 0 ? true : false
    }
    const _getMyDonationByCC = (donations: any) => {
        let amount = 0;
        if (userid) {
            donations.forEach((d: any) => {
                if (userid === d.donatedBy) amount += d.amount;
            });
            return amount;
        }
        else return amount;
    }

    useEffect(() => {
        setIsLoading(true)
        DonorsAPI.getDonationsByDonor(localStorage.getItem(ACTION_CONSTANTS.USERID)).then((res: any) => {
            if (res && res.length > 0) {
                let selectedProgram = res.filter((r: any) => r.code === props.program.code)
                setProgram(selectedProgram.length ? selectedProgram[0] : props.program);
            }
            else setProgram(props.program)
            setIsLoading(false)
        })
    }, [props.program])

    const checkCostHeads = (cc: any) => {
        setProgram((prev: any) => {
            const updated = { ...program };
            setchekedCosthead(null)
            if (updated && updated.costCodes) {
                updated.costCodes = updated.costCodes.map((costcodes: any) => {
                    if (costcodes.code != cc.code) {
                        costcodes.checked = false
                    } else {
                        costcodes.checked = true
                        setchekedCosthead(costcodes)
                    }
                    return costcodes
                })
            }

            return updated;
        });
    }
    return (
        <>
            <Dialog PaperProps={{ className: 'donationDialogBox' }}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                disableEscapeKeyDown={true}
            >
                {isLoading && <Loader />}
                <div className='dialogHeader flex flex-wrap justify-content-between'>
                    {program && <>
                        <div className='flex programHeader'>
                            <span className='flex donateToContainer'>Donate to &nbsp;&nbsp;<b className={'subheader flex ' + (app === ACTION_CONSTANTS.EDAAN ? 'edaanHeader' : 'epujoHeader')}>{program.name}</b></span>
                            <span className='flex campaignname'>{program.campaignName}</span>
                        </div>
                        <div className='flex'>
                            <div className='costHeadTarget flex'>
                                <span className='target'>Total Fund Raised</span>
                                <b className='fundRaised'><Rupee />{program.totalFundRaised}</b>
                            </div>
                            <div className='costHeadTarget flex'>
                                <span className='target'>Fundraiser Target</span>
                                <b className='projectBudget'><Rupee />{program.projectBudget}</b>
                            </div>
                        </div>
                    </>}
                </div>
                <div className='dialogBody'>
                    <div className='costCodesWrap'>
                        {
                            program && program.costCodes && program.costCodes.map((cc: any) => (
                                <div key={cc.code} className='costCodeRow flex flex-wrap'>
                                    <div className='headname flex'>
                                        <div><Checkbox
                                            checked={chekedCosthead && chekedCosthead.code == cc.code && cc.checked ? true : false}
                                            onChange={(e) => checkCostHeads(cc)}
                                            sx={{ '& .MuiSvgIcon-root': { } }} className='checkbox'/>
                                        </div>
                                        <div>{cc.name}</div></div>
                                    <div className='flex costAmounts'>
                                        <input type='number' className={(!cc.checked && 'disabledItem') + ' inputDonateAmount '} placeholder='Enter amount..' onKeyUp={(e) => _onAmountEnter(e, cc.code)} />
                                        <div className='flex webViewTargets'>
                                            <div className='costHeadTarget flex'>
                                                <span className='target'>You Donated</span>
                                                <b className={'fundRaised ' + (!userid && 'disabledItem')}><Rupee />{_getMyDonationByCC(cc.donations)}</b>
                                            </div>
                                            <div className='costHeadTarget flex'>
                                                <span className='target'>Target</span>
                                                <b><Rupee />{cc.budget}</b>
                                            </div>
                                        </div>
                                        <div className='flex mobileViewTargets'>
                                            <b className={'fundRaised ' + (!userid && 'disabledItem')}><Rupee />{_getMyDonationByCC(cc.donations)}</b>
                                            <b><Rupee />{cc.budget}</b>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='dialogFooter flex'>
                    <div className={styles.tertiaryBtnStyle + ' donateBtn'} onClick={() => onActionClick('donate')}><FaHeart className='icon' />DONATE</div>
                    <div className={styles.tertiaryBtnStyle + ' cancelBtn'} onClick={() => onActionClick('close')}>Cancel</div>
                </div>
            </Dialog>

        </>
    )
}
export default DonationDialog;