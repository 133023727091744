import { _get, _post } from "./API";
const baseURL = process.env.REACT_APP_DMS_ENDPOINT;
const backendURL = process.env.REACT_APP_DONATION_ENDPOINT;
export const DmsAPI = {

    upload: async function(files: any) {
        var formData = new FormData();
        let meta: any = [];
        files.forEach((fileObj: any) => {
            if (fileObj && fileObj.type) meta.push({'doctype': fileObj.type.code, 'docname': fileObj.file.name});
            formData.append('files',fileObj.file);
        });
        if (meta.length>0) formData.append('meta', JSON.stringify(meta));
        // let filesToSend: [] = files.map((f: any) => f.file);
        // filesToSend.forEach(file => {
        //     formData.append('files',file);
        // })
        return await _post(`${backendURL}/dms/fileupload`, formData);
    },
    getDocumentUrl: function(dmscode: string){
        let token = localStorage.getItem('token')
        let encodedToken = token ? btoa(token) : null;
        return encodedToken ? `${baseURL}/download/${dmscode}?base64EncStr=${encodedToken}` : `${baseURL}/download/${dmscode}`
    },
}