import { CarouselComponent } from "./Carousel/CarouselComponent"
import { ProgramCard } from "./ProgramCard/ProgramCard"
import { ACTION_CONSTANTS, CardsLayout, getImageUrl, getPercent } from "../../configs/Utils"
import { ProgressBar } from "react-bootstrap";
import { FaHeart, FaFileDownload } from "react-icons/fa";
import { MdOutlineFileDownload, MdRemoveRedEye } from "react-icons/md";
import { downloadFile } from "../../configs/Utils";
import React from "react";
import { FullScreenImageDialog } from "../Dialogs/FullScreenImageDialog/FullScreenImageDialogComponent";
const app = localStorage.getItem('app');

export const Rupee = () => {
    const styles = JSON.parse(localStorage.getItem('styles') as string);
    return (
        <span style={{ color: `var(--secondary-color)` }}><span>{'\u20B9'}</span>&nbsp;</span>
    )
}

export const Logo = () => {
    let img = app === ACTION_CONSTANTS.EPUJO ? "/images/wbgov.png" : "/images/edaanlogo.png";
    return <img src={process.env.PUBLIC_URL + img} alt="logo" />;
}

export const Heading = (props: { data: string, subdata?: string }) => {
    const styles = JSON.parse(localStorage.getItem('styles') as string);
    return (<>
        <div className={'subheader flex ' + (app === ACTION_CONSTANTS.EDAAN ? 'edaanHeader' : 'epujoHeader')}> {props.data} </div>
        {props.subdata ? <div className='subheaderInfo flex'> {props.subdata} </div> : <></>}
        <hr className={(styles ? styles.headerUnderline : '')}/>
    </>)
}
export const Footer = () => {
    return (
        <h6 className="footerText">Developed by Department of IT&E</h6>
    )
}
export const TopFundraisers = (props: { heading: string, data: any }) => {
    const styles = JSON.parse(localStorage.getItem('styles') as string);
    return (
        <div className="widgetGap">
            <Heading data={props.heading} />
            {props.data.length > 0 && <CarouselComponent cards={props.data} showCount={1} />}
                {/* <div className='showAllFooter flex' style={{ 'marginTop': '2vw', borderTop: '1px solid #eee' }}>Show All</div> */}
        </div>
    )
}

export const UrgentRequirement = (props: { heading: string, data: any, size?: 'small' }) => {
    const styles = JSON.parse(localStorage.getItem('styles') as string);
    return (
        <div className="widgetGap">
            <Heading data={props.heading} />
            {props.data && <ProgramCard program={props.data} size={props.size} />}
        </div>
    )
}

export const TopDonors = (props: { heading: string, data: any }) => {
    const styles = JSON.parse(localStorage.getItem('styles') as string);
    return (
        <div className="widgetGap">
            <Heading data={props.heading} />
            <div className='widgetWrapper'>
                {props.data.length > 0 && <CarouselComponent cards={props.data} showCount={2} entity='contributors' vertical={true} />}
                {/* <div className='showAllFooter flex'>Show All</div> */}
            </div>
        </div>
    )
}
export const AllFundraisersGrid = (props: { heading: string, data: any }) => {
    const styles = JSON.parse(localStorage.getItem('styles') as string);
    return (
        <div className="widgetGap">
            <Heading data={props.heading} />
            {/* <div className='widgetWrapper'> */}
            {props.data.length > 0 && <CardsLayout entity="fundraiser" cards={props.data} count={4} hideDetails={true} />}
            {/* </div> */}
        </div>
    )
}

export const FundraisersByCategories = (props: { heading?: string, data: any, onCardClick: any }) => {
    const styles = JSON.parse(localStorage.getItem('styles') as string);
    return (
        <div className="widgetGap">
            {props.heading && <Heading data={props.heading} />}
            <div className='widgetWrapper fundraiserCategoriesWrap'>
                {props.data.length > 0 &&
                    props.data.map((d: any, idx: number) => (
                        <div key={idx} className="categoryWrapper">
                            <div className={"categoryName flex " + styles.fontColorLight}>{d.name}</div>
                            <FundraisersSummaryCards fundraisers={d.fundraisers} onCardClick={props.onCardClick} />
                        </div>
                    ))
                }
                {/* <div className='showAllFooter flex'>Show All</div> */}
            </div>
        </div>
    )
}

export const FundraisersSummaryCards = (props: { fundraisers: any, onCardClick: any }) => {
    const styles = JSON.parse(localStorage.getItem('styles') as string);
    return (
        <div className="categoryBreakup">
            {
                props.fundraisers.map((f: any, idx: number) => (
                    <div key={idx} className='smallCard flex' onClick={() => props.onCardClick(f.code)}>
                        <div className="cardHeader">{f.name}</div>
                        <FundInfo data={f} />
                    </div>
                ))
            }
        </div>
    )
}


export const Tabs = (props: { options: any, selectedOption: any, tabSelection: any }) => {
    const styles = JSON.parse(localStorage.getItem('styles') as string);
    return (
        <div className='flex useroptionContainer'>
            {
                props.options.map((a: any) => (
                    <div key={a} className={'useroption ' + (props.selectedOption === a ? `selectedOptionClass ${styles.selectedOptionClass}` : '')} onClick={() => props.tabSelection(a)}>{a}</div>
                ))
            }
        </div>
    )
}

export const CostBreakup = (props: { program: any }) => {
    const styles = JSON.parse(localStorage.getItem('styles') as string);
    const _extractCostBreakup = (costhead: any) => {
        let fund = 0;
        costhead.donations.forEach((d: any) => {
            if (d.amount) fund += d.amount;
        });
        return { totalFundRaised: fund, projectBudget: costhead.budget, totalUniqueDonors: costhead.donations.length };
    }
    return (
        <div className='widgetGap'>
            <Heading data={app === ACTION_CONSTANTS.EPUJO ? 'All Contributions Summary' : 'All Donations Summary'} />
            <div className="categoryBreakup">
                {
                    props.program ?.costCodes && props.program.costCodes.map((ch: any) => (
                        <div key={ch.code} className='smallCard flex'>
                            <div className="cardHeader">{ch.name}</div>
                            <FundInfo data={_extractCostBreakup(ch)} />
                            {/* <div className="showExpenseBtn" onClick={_}><MdRemoveRedEye/>&nbsp;View Expenses</div> */}
                        </div>
                    ))
            }
            </div>
        </div>
    )
}


export const CategoryFilter = (props: { data: any, selections: any, _onSelection: any }) => {
    const styles = JSON.parse(localStorage.getItem('styles') as string);
    return (
        <div className={styles.categoryWrap + ' formWrap'}>
            {
                props.data.map((item: any) => (
                    <div key={item.code} className={styles.selectionRow + ' ' + (props.selections.filter((a: any) => a.code == item.code).length > 0 && styles.categorySelection)}
                        onClick={() => props._onSelection(item)}>
                        {item.name}
                    </div>
                ))
            }
        </div>
    )
}


export const FundInfo = (props: { data: any }) => {
    const styles = JSON.parse(localStorage.getItem('styles') as string);
    const app = localStorage.getItem('app');
    return (
        <div className={(app === ACTION_CONSTANTS.EDAAN ? 'edaanfundInfo' : 'epujofundInfo') + ' fundInfo'} style={{ width: '100%' }}>
            <div className="subinfo flex">
                <div className="flex" style={{alignItems:'baseline'}}>
                    <b><span>{'\u20B9'}</span>&nbsp;{props.data.totalFundRaised | 0}</b>
                    &nbsp;{props.data.projectBudget ? <span>/&nbsp;{props.data.projectBudget}</span> : ''}
                </div>
                <div className="supporter flex"><FaHeart className="icon"/>&nbsp;{props.data.totalUniqueDonors | 0}</div>
            </div>
            <ProgressBar now={getPercent(props.data.totalFundRaised | 0, props.data.projectBudget)} />

        </div>

    )
}

export const ImageGallery = (props: { images: any, count?: number }) => {
    const styles = JSON.parse(localStorage.getItem('styles') as string);
    const [openFullImgDialog, setOpenFullImgDialog] = React.useState(false);
    const [fullImgUrl, setFullImgUrl]= React.useState("");
    const [selectedImage, setSelectedImage]= React.useState("");

    const openDialog = (img:any)=>{
        setSelectedImage(img)
        setFullImgUrl(getImageUrl(img));
        setOpenFullImgDialog(true)
    }
    const eventdialogAction = (action: string) => {
        setOpenFullImgDialog(false);
    }
    return (
        <>
        { openFullImgDialog && <FullScreenImageDialog selected={selectedImage} imageList={props.images} dialogAction={eventdialogAction}></FullScreenImageDialog> }
        <div className='imagesWrap' style={{ columnCount: props.count ? props.count : 3 }}>
            {props.images.map((image: any) => (
                <div key={image.code} className='imageInputWrap relative'>
                    <img className='image' src={getImageUrl(image)} onClick={()=> openDialog(image)} alt="image" />
                    {/* {image.name && <div className='imageText absolute' >{image.name}</div>} */}
                </div>
            ))}
        </div>
        </>
    )
}


export const StatusAlert = (props: { type: string, text: string, subtext?: string, onClose: any }) => {
    const styles = JSON.parse(localStorage.getItem('styles') as string);
    return (
        <div className={'alertWrap absolute ' + `${props.type}Bg` + (props.text ? '' : ' displayNone')}>
            <div className='alertType'>{props.type}</div>
            <div className='alertText'>{props.text}</div>
            {/* <div className='alertsubText'>{props.subtext}</div> */}
        </div>
    )
}

export const CustomBadges = (props: { data: any ,isdoc?:boolean}) => {
    const styles = JSON.parse(localStorage.getItem('styles') as string);
    let usertype = localStorage.getItem('userType');
    return (
        <>
            {props.data.map((cat: any, idx: number) => (
                <>
                    { (usertype === ACTION_CONSTANTS.COMMITTEE || usertype === ACTION_CONSTANTS.ORGANISER || cat.public) && 
                        <div key={idx} className={'categoryBadge inline ' + styles.fontColorLight}>
                            <div className='flex align-items-center'>
                                <div>{cat.name}</div>
                                {props.isdoc && <div className="icon" onClick={() => downloadFile(cat.url)}><MdOutlineFileDownload /></div>}
                            </div>
                        </div>
                    }
                </>
            ))
            }
        </>
    );
}

export const Years = (props: { years: any, selectedYear: string, onSelectYear: any }) => {
    const styles = JSON.parse(localStorage.getItem('styles') as string);
    return (
        <div className="yearsWrap">
            {
                props.years.map((y: string) => (
                    <div key={y} className={"year inline " + (props.selectedYear === y && 'selectedYear')} onClick={() => props.onSelectYear(y)}>{y}</div>
                ))
            }
        </div>
    )
}