import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { MdChevronLeft, MdChevronRight, MdClose } from 'react-icons/md';
import './FullScreenImageDialogComponent.scss'
import { getImageUrl } from "../../../configs/Utils"

export const FullScreenImageDialog = (props: { selected: string, dialogAction: any, imageList: any }) => {
  const [open, setOpen] = React.useState(true);
  const [selected, setSelectedImage] = React.useState<any>();
  const [selectedTransition, setTransition] = React.useState<any>();


  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction={selectedTransition} ref={ref} {...props} />;
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.dialogAction();
  };
  const findIndex = (element: any) => {
    return element.code == selected.code
  }
  const scrollImage = (opt: string) => {
    const selectedIndex = props.imageList.findIndex(findIndex)
    let indexToSet = 0
    switch (opt) {
      case 'prev':
        indexToSet = selectedIndex > 0 ? selectedIndex - 1 : props.imageList.length - 1;
        setTransition('left')
        break;
      case 'next':
        if (props.imageList.length > 1)
          indexToSet = selectedIndex < props.imageList.length - 1 ? selectedIndex + 1 : 0;
          setTransition('right')
        break;
    }
    setSelectedImage(props.imageList[indexToSet])

  }
  React.useEffect(() => {
    if (props.selected) {
      setSelectedImage(props.selected)
    }
  }, [])
  return (
    <Dialog
      PaperProps={{ className: 'imageDialog' }}
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <div className='relative' style={{ height: '100%', width: '100%', background: 'black', textAlign: 'center' }}>
        <MdClose className='absolute imaegDialogcloseBtn' onClick={handleClose} />
        {/* <img src={props.url} alt='image' style={{height: '100%',maxWidth: '100%'}}></img> */}
        <div className="bgImg" style={{ backgroundImage: `url("${getImageUrl(selected)}")` }}></div>
      </div>
      {/* <BiSolidChevronLeftSquare/> */}
      <span className='chevron chevron-right' onClick={() => scrollImage('next')}><MdChevronRight /></span>
      <span className='chevron chevron-left' onClick={() => scrollImage('prev')}><MdChevronLeft /></span>

    </Dialog>
  );
}
